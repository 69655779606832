var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-business-domestic'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('page-title-operate'), _c('tab-business-domestic'), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "용인시 기흥노인복지관",
      "file": "yi",
      "url": "http://www.ygsenior.or.kr/"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 노인여가복지시설(노인복지관) ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 용인시 기흥구 산양로 71 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 2023.40㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2015. 4. 2 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 1,000명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 임형규 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 46명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 평생교육‧취미여가지원사업, 건강생활지원사업, 상담사업, 정서 및 사회생활지원사업, 주거지원사업, 위기 및 독거노인지원사업, 사회활동‧사회참여지원사업, 고용‧소득지원사업, 가족통합지원사업 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "2",
      "tit": "영등포시니어클럽",
      "file": "ydp",
      "url": "https://ydpsc.or.kr/"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 노인복지시설 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 서울시 영등포구 영중로 119 리마크빌오피스텔 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 166.82㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2017년 10월 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 930명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 이종화 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 13명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 공익형 어르신 일자리 사업, 사회서비스형 어르신 일자리 사업, 시장형 어르신 일자리 사업, 인력파견형 어르신 일자리 사업 등 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "성북시니어클럽",
      "file": "sbs",
      "url": "http://www.sbsc9988.or.kr/"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 노인복지시설 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 서울시 성북구 안암로 79 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 66.12 ㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2018.06.01 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 1,005명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 구본규 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 14명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 총괄사업으로는 공익형 (573명), 사회서비스형 (213명), 시장형 (200명), 취업알선형(19명)으로 사업 진행 중입니다."), _c('br'), _vm._v(" 성북시니어클럽의 대표사업으로는 일상생활지원센터 (24명), 요리노리체험교실 (24명), 자연노리체험교실 (12명), 커피정원 (18명), 공동작업장 1호점 (40명) 이 있습니다. ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "광명시니어클럽",
      "file": "gm",
      "url": "https://www.gmsjob.co.kr/"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 노인복지시설 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 경기도 광명시 연서일로 4-3, 4층 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 151.5㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2021.07.01 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 822명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 김은숙 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 14명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 공익형 어르신 일자리 사업, 사회서비스형 어르신 일자리 사업, 시장형 어르신 일자리 사업, 취업알선형 어르신 일자리 사업 등 ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }