<template>
    <client-page>

        <sub-visual-business-domestic />

        <section class="section">
            <v-container>

                <page-title-operate />

                <tab-business-domestic />

                <business-item imageGrid="4" tit="용인시 기흥노인복지관" file="yi" url="http://www.ygsenior.or.kr/">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 노인여가복지시설(노인복지관)
                        </li>
                        <li class="dot-text">
                            소재지 : 용인시 기흥구 산양로 71
                        </li>
                        <li class="dot-text">
                            시설규모 : 2023.40㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2015. 4. 2
                        </li>
                        <li class="dot-text">
                            이용정원 : 1,000명
                        </li>
                        <li class="dot-text">
                            시설장 : 임형규
                        </li>
                        <li class="dot-text">
                            종사자 : 46명
                        </li>
                        <li class="dot-text">
                            주요사업 : 평생교육‧취미여가지원사업, 건강생활지원사업, 상담사업, 정서 및 사회생활지원사업, 주거지원사업, 위기 및 독거노인지원사업, 사회활동‧사회참여지원사업, 고용‧소득지원사업, 가족통합지원사업
                        </li>
                    </ul>
                </business-item> 

                <business-item imageGrid="2" tit="영등포시니어클럽" file="ydp" url="https://ydpsc.or.kr/">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 노인복지시설
                        </li>
                        <li class="dot-text">
                            소재지 : 서울시 영등포구 영중로 119 리마크빌오피스텔
                        </li>
                        <li class="dot-text">
                            시설규모 : 166.82㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2017년 10월
                        </li>
                        <li class="dot-text">
                            이용정원 : 930명
                        </li>
                        <li class="dot-text">
                            시설장 : 이종화
                        </li>
                        <li class="dot-text">
                            종사자 : 13명
                        </li>
                        <li class="dot-text">
                            주요사업 : 공익형 어르신 일자리 사업, 사회서비스형 어르신 일자리 사업, 시장형 어르신 일자리 사업, 인력파견형 어르신 일자리 사업 등
                        </li>
                    </ul>
                </business-item> 

                <business-item imageGrid="4" tit="성북시니어클럽" file="sbs" url="http://www.sbsc9988.or.kr/">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 노인복지시설
                        </li>
                        <li class="dot-text">
                            소재지 : 서울시 성북구 안암로 79
                        </li>
                        <li class="dot-text">
                            시설규모 : 66.12 ㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2018.06.01
                        </li>
                        <li class="dot-text">
                            이용정원 : 1,005명
                        </li>
                        <li class="dot-text">
                            시설장 : 구본규
                        </li>
                        <li class="dot-text">
                            종사자 : 14명
                        </li>
                        <li class="dot-text">
                            주요사업 : 총괄사업으로는 공익형 (573명), 사회서비스형 (213명), 시장형 (200명), 취업알선형(19명)으로 사업 진행 중입니다.<br>
                            성북시니어클럽의 대표사업으로는 일상생활지원센터 (24명), 요리노리체험교실 (24명), 자연노리체험교실 (12명), 커피정원 (18명), 공동작업장 1호점 (40명) 이 있습니다.
                        </li>
                    </ul>
                </business-item> 

                <business-item imageGrid="4" tit="광명시니어클럽" file="gm" url="https://www.gmsjob.co.kr/">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 노인복지시설
                        </li>
                        <li class="dot-text">
                            소재지 : 경기도 광명시 연서일로 4-3, 4층
                        </li>
                        <li class="dot-text">
                            시설규모 : 151.5㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2021.07.01
                        </li>
                        <li class="dot-text">
                            이용정원 : 822명
                        </li>
                        <li class="dot-text">
                            시설장 : 김은숙
                        </li>
                        <li class="dot-text">
                            종사자 : 14명
                        </li>
                        <li class="dot-text">
                            주요사업 : 공익형 어르신 일자리 사업, 사회서비스형 어르신 일자리 사업, 시장형 어르신 일자리 사업, 취업알선형 어르신 일자리 사업 등
                        </li>
                    </ul>
                </business-item> 

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualBusinessDomestic from "@/components/client/sub/sub-visual-business-domestic.vue";
import TabBusinessDomestic from '@/components/client/sub/tab-business-domestic.vue';
import PageTitleOperate from '@/components/client/sub/page-title-operate.vue';
import BusinessItem from '@/components/client/sub/business-item.vue';
export default {
    components: {
        ClientPage,
        SubVisualBusinessDomestic,
        TabBusinessDomestic,
        PageTitleOperate,
        BusinessItem,
    },
};
</script>